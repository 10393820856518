import React from "react";

export default function Routard(props) {
  return (
    <img
      data-src="https://res.cloudinary.com/zharkan/image/upload/f_auto,q_auto/routard"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      width="38%"
      alt="recommandé par le guide du routard"
      className="lazyload routard"
    />
  );
}
