import React, { Suspense } from 'react';
import './historique.css';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {Helmet} from "react-helmet";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
const HistoriqueModal = React.lazy(() => import('../utilitaires/Modalhistorique'));



export default function Historique() {
  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Philippe Coudy",
    "url": "https://www.mediarestauration.fr/historique",
    "image": "https://static.mediarestauration.fr/img/photos/philippe-coudy-min-nb.jpg",
    "sameAs": [
      "https://fr.linkedin.com/in/philippe-coudy-6a026646",
      "https://www.mediarestauration.fr/historique"
    ],
    "jobTitle": "PDG/CEO",
    "worksFor": {
      "@type": "Organization",
      "name": "Media Restauration France"
    }  
  }
  const { trackPageView } = useMatomo()
  React.useEffect(() => {
  trackPageView()
}
, [])
    return (
      <>
      <Helmet>
      {/*<!-- Primary Meta Tags -->*/}
      <script type="application/ld+json">
  {JSON.stringify(ldJson)}
</script>
<title>Notre histoire, notre équipe -  Media Restauration France - Consultant professionnel</title>
<meta name="title" content="Notre histoire, notre équipe -  Media Restauration France - Consultant professionnel par Philippe Coudy (PDG)"/>
<meta name="description" content="Découvrez l'équipe et l'histoire derrière MEDIA Restauration fondée par notre PDG Philippe Coudy, consultant pour tous vos projets de fooding à Rouen."/>

{/*<!-- Open Graph / Facebook -->*/}
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.mediarestauration.fr/historique"/>
<meta property="og:title" content="Notre histoire, notre équipe -  Media Restauration France - Consultant professionnel par Philippe Coudy (PDG)"/>
<meta property="og:description" content="Découvrez l'équipe et l'histoire derrière MEDIA Restauration fondée par notre PDG Philippe Coudy, consultant pour tous vos projets de fooding à Rouen."/>
<meta property="og:image" content="https://www.mediarestauration.fr/fond-historique-partager.png"/>

{/*<!-- Twitter -->*/}
<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://www.mediarestauration.fr/historique"/>
<meta property="twitter:title" content="Notre histoire, notre équipe -  Media Restauration France - Consultant professionnel par Philippe Coudy (PDG)"/>
<meta property="twitter:description" content="Découvrez l'équipe et l'histoire derrière MEDIA Restauration fondée par notre PDG Philippe Coudy, consultant pour tous vos projets de fooding à Rouen."/>
<meta property="twitter:image" content="https://www.mediarestauration.fr/fond-historique-partager.png"/>

      </Helmet>
      <div id="historique">
      <div id="page">
             <div className="entete">
               <div className="container-fluid ">
                 <div className="col-12">
                   <div className="row flex-sm-row flex-lg-row g-0">
                     <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                       <h1 className="pb-lg-3 pt-2">MEDIA Restauration France</h1>
                       <div className="entete__bordure">
                         <h2 className="pt-3 pt-sm-0 d-block d-sm-block d-md-none focus-text-entrant">Découvrez notre histoire</h2>
                         <h2 className="pt-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">Découvrez notre histoire</h2>
                         <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">Depuis plus de 30 ans maintenant...</h3>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="container-fluid espace" id="fond-gris">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                 <div className="col-3 offset-xs-1 d-block d-md-block d-sm-block d-lg-block d-xl-none d-xxl-none pb-5 mb-3 ml-3">
                   <div id="timeline-wrap">
                     <div id="timeline" />
                     {/* This is the individual marker*/}
                     <div className="marker mfirst timeline-icon one">
                       1990
                     </div>
                     {/* / marker */}
                     {/* This is the individual marker*/}
                     <div className="marker mlast timeline-icon four">
                       2021
                     </div>
                     {/* / marker */}
                   </div>
                 </div>
                 <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                   <div className="row d-flex entete__bordure">
                     <div className="col-3 align-self-center">
                       <div id="timeline-wrap">
                         <div id="timeline" />
                         {/* This is the individual marker*/}
                         <div className="marker mfirst timeline-icon one">
                           1990
                         </div>
                         {/* / marker */}
                         {/* This is the individual marker*/}
                         <div className="marker mlast timeline-icon four">
                           2021
                         </div>
                         {/* / marker */}
                       </div>
                     </div>
                     <div className="col-9">
                       <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">L'histoire derrière <span className="d-block pl-5 couleur">Media Restauration</span></h5>
                     </div>
                   </div>
                 </div>
                 <h5 className="text-center d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">L'histoire derrière <span className="d-block couleur">Media Restauration</span></h5>
               </div>
               <div className="col-xs-12 col-md-12 col-lg-10 mx-auto pt-5">
                 
                 <div className="row">
                   <div className="col-xs-12 col-md-12 col-lg-6 align-self-center">
                     <h4 className="text-center">Tout commence avec Station Restauration, filiale de MRF...</h4>
    <p>Au début des années 1990 Philippe Coudy et le Chef doublement étoilé Gilles Tournadre furent les premiers à lancer ensemble une initiative novatrice : des cafés et bistros en gare référencés
      dans le guide Michelin. Présent dans de nombreuses villes telles que Rouen, Le Havre, Amiens, Besançon, Chambéry, Valence TGV et Valence Ville, Montélimar, Narbonne, Montauban, Cahors, 
      Saint-Etienne, Angers sous les marques Pain Soleil et Cie, Pub Station et Le Bistrot du chef... en gare.
    </p>
    
                   </div>
          <div className="col-xs-12 col-md-12 col-lg-6">
            <h3 className="text-center">
      Station Restauration en quelques chiffres c'était :</h3>
    <div className="d-flex flex-column justify-content-center justify-content-lg-around" id="compte">
      <p className="text-center p-5 pb-1"><CountUp end={11.5} duration="4.75" decimals="1" decimal="." suffix=" Millions de CA" redraw={true}>
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef}  />
                </VisibilitySensor>
            )}
        </CountUp></p>
        <p className="text-center p-4 pb-1"><CountUp end={230} duration="3.75" suffix=" Collaborateurs" redraw={true}>
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef}  />
                </VisibilitySensor>
            )}
        </CountUp>
        </p>
        <p className="text-center p-3 pb-1"><CountUp end={14} delay="1" duration="4.75" suffix=" Villes" redraw={true}>
            {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef}  />
                </VisibilitySensor>
            )}
        </CountUp>
        </p>
    </div>
            
          </div>
                 </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 mx-auto pt-5">
                   <p className="text-justify">Depuis plus de 30 ans maintenant, MEDIA Restauration a su se distinguer par son <span className="couleur"><strong>expérience entrepreneuriale</strong></span>, sa connaissance 
      rigoureuse du terrain et son <span className="couleur"><strong>expertise aiguisée en gestion CHR</strong></span>. </p>
      <h4 className="text-center mt-2">Nos autres créations :</h4>
      <p className="text-center">
Pub station, Fnac Café, Bodega Station, Pain Soleil, Bistro du Chef en Gare.</p>

<div className="d-flex flex-column flex-sm-row justify-content-center">
  <img data-src="/images/pub_station.png" className="img-fluid basse__resolution mx-auto lazyload" alt="pub station"/>
  <img data-src="/images/pain_soleil.png" className="img-fluid basse__resolution mx-auto lazyload" alt="pain soleil"/>
  <img data-src="/images/bistrot_du_chef.png" className="img-fluid basse__resolution mx-auto lazyload" alt="bistrot du chef"/>
</div>
                </div>



               </div>

               <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                 
                 <p><AnchorLink href='#equipe' offset={() => 100} className="bouton">Découvrir l'équipe&nbsp;&nbsp;&gt;</AnchorLink></p>
                 
               </div>
             </div>
             <div className="lazyload" id="parallax1" data-bg="https://static.mediarestauration.fr/img/gros-horloge-image_l-min.jpg">
             </div>
             <div className="container-fluid" id="equipe">
               <div className="col-12 d-flex inter">
                 <div className="row g-0 align-content-center w-100 mt-5 mb-5">
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                     <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                       <h5 className="text-center">Une équipe à l'affût  <span className="d-block couleur">des dernières innovations</span></h5>
                     </span>
                     <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                       <div className="row d-flex entete__bordure fond_texte">
                         <div className="col-4 align-self-center">
                           <h6 className="couleur"><em>Dédiée à vos projets</em></h6>
                         </div>
                         <div className="col-8 align-self-center">
                           <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">Une équipe<span className="d-block couleur"><strong>de professionnels</strong></span></h5>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>

                   <div className="col-xs-12 col-sm-12 col-lg-12 col-xxl-8 mx-auto text-center pb-5">
                     <p className="pt-2 pb-5">Découvrez ici les profils des membres de notre équipe :</p>
                     <Suspense fallback={<div>Chargement...</div>}>
                  <HistoriqueModal></HistoriqueModal>
                  </Suspense>
                   
                   </div>


             </div>
             <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
               <p><Link to="/nos-projets" className="bouton">Voir les projets&nbsp;&nbsp;&gt;</Link></p>
             </div>
           </div>
           </div>
           </>
    );
}
