import React from 'react';
import './nosProjets.css';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

export default function NosProjets () {
  const { trackPageView } = useMatomo()
React.useEffect(() => {
trackPageView()
}, [])
    return (
      <>
       <Helmet>
      {/*<!-- Primary Meta Tags -->*/}
<title>Découvrez nos projets -  Media Restauration France - Consultant professionnel</title>
<meta name="title" content="Découvrez nos projets -  Media Restauration France - Consultant professionnel"/>
<meta name="description" content="Les projets en cours chez MEDIA Restauration, consultant pour tous vos projets de fooding."/>

{/*<!-- Open Graph / Facebook -->*/}
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.mediarestauration.fr/nos-projets"/>
<meta property="og:title" content="Découvrez nos projets -  Media Restauration France - Consultant professionnel"/>
<meta property="og:description" content="Les projets en cours chez MEDIA Restauration, consultant pour tous vos projets de fooding."/>
<meta property="og:image" content="https://www.mediarestauration.fr/fond-projets-partager.png"/>

{/*<!-- Twitter -->*/}
<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://www.mediarestauration.fr/nos-projets"/>
<meta property="twitter:title" content="Découvrez nos projets -  Media Restauration France - Consultant professionnel"/>
<meta property="twitter:description" content="Les projets en cours chez MEDIA Restauration, consultant pour tous vos projets de fooding."/>
<meta property="twitter:image" content="https://www.mediarestauration.fr/fond-projets-partager.png"/>

      </Helmet>
      <div id="nosProjets">
      <div id="page">
           <div className="entete">
             <div className="container-fluid ">
               <div className="col-12">
                 <div className="row flex-sm-row flex-lg-row g-0">
                   <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                     <h1 className="pb-lg-3 pt-2">MEDIA Restauration France</h1>
                     <div className="entete__bordure">
                       <h2 className="pt-3 pt-sm-0 d-block d-sm-block d-md-none focus-text-entrant">Découvrez en détails tous nos projets</h2>
                       <h2 className="pt-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">Découvrez en détails tous nos projets</h2>
                       <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">Les projets en cours</h3>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div className="col-12 d-flex inter espace pt-5">
             <div className="row g-0 align-content-center w-100 mt-5 mb-5">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                 <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                   <h5 className="text-center"><img data-src="https://static.mediarestauration.fr/img/boujou-logo.svg" className="lazyload d-block d-sm-block d-md-block d-lg-block d-xl-none mx-auto img-fluid" alt="logo boujou" width="45%" /> <span className="d-block couleur">l'excellence de la Normandie exportée</span></h5>
                 </span>
                 <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                   <div className="row d-flex entete__bordure fond_texte">
                     <div className="col-4 align-self-center">
                       <h6 className="couleur"><em>Normandy French art de vivre</em></h6>
                     </div>
                     <div className="col-8 align-self-center">
                       <span className="d-flex justify-content-end pt-3 pb-3"><img data-src="https://static.mediarestauration.fr/img/boujou-logo.svg" className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block img-fluid" alt="logo boujou" width="35%" /></span>
                     
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className="container-fluid pt-5 pb-5 fond-blanc">
                 <div className="col-xs-3 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-6 mb-5 mb-sm-0 order-1 mx-auto">
                 <h4>Le projet Boujou en quelques mots</h4>
                 <ul className="pl-4 pl-lg-5 pt-3">
   <li>
   Le projet : créer un concept store exporté dans les grandes capitales internationales,
   un espace hybride de 2000 m2 où trouver gastronomie (brasserie normande, bar à cidre et Calvados, cours de cuisine), hall marchande (boulangerie, crémerie, boucherie...),
   les marques normandes les plus raffinées (Mauviel, St james, Maison Berger...) mais aussi lieu de rencontre,
   d'échange et de découverte autour de la culture Made In Normandie. <span className="couleur"><strong>Le meilleur de la Normandie partout dans le monde</strong></span>.
   </li>
   <li>
     Ayant une vocation internationale, les espaces Boujou peuvent se dupliquer
    dans les grandes métropoles internationales comme New-York, Londres, Shanghai ou Abou Dabi.
   </li>
</ul>
                 </div>
           </div>
           <div className="lazyload" id="parallax1" data-bg="https://static.mediarestauration.fr/img/parallax1-nos-projets-boujou.jpg">
           </div>
           <div className="container-fluid">
             <div className="col-12 d-flex inter">
               <div className="row g-0 align-content-center w-100 mt-5 mb-5">
                 <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">

                   <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                   <h5 className="text-center"><img data-src="https://static.mediarestauration.fr/img/cafe-hamlet-logo.svg" className="lazyload d-block d-sm-block d-md-block d-lg-block d-xl-none mx-auto img-fluid" alt="logo Hamlet" width="35%" />Snacking chic</h5>
                   </span>

                   <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                     <div className="row d-flex entete__bordure fond_texte">
                       <div className="col-4 align-self-center">

                       
                         <h6 className="couleur"><em>Snacking chic dans un lieu unique</em></h6>

                       </div>
                       <div className="col-8 align-self-center">
                       <span className="d-flex justify-content-end pt-3"><img data-src="https://static.mediarestauration.fr/img/cafe-hamlet-logo.svg" className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block img-fluid" alt="logo Hamlet" width="35%" /></span>
                         <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block"><span className="d-block couleur"><strong>by Gilles</strong></span></h5>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="col-12 mx-auto pb-5">
               <div className="row justify-content-center mx-auto">
               <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 col-xxl-4 mb-5 mb-sm-0 align-self-center order-1">
                 
                 <h4>Aître ou ne pas Être</h4>
                 <ul className="pl-4 pl-lg-5 pt-3">
   <li>
   Une cuisine simple alliée à une élégance
gastronomique pour un snacking chic normand
signé by Gill.
</li>

<li>
  Des produits bruts, locaux, orientés slow food pour
  une cuisine éthique et savoureuse.
</li>

<li>
  Un Chef, Gilles Tournadre, deux étoiles depuis
  maintenant 30 ans et ambassadeur de l’excellence normande.
</li>
</ul>
                 </div>
                 <div className="col-xs-3 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5 mb-5 align-self-center mb-sm-0 order-2">
                 <h4>Speakeasy normand et snacking chic</h4>
                 <ul className="pl-4 pl-lg-5 pt-3">
                 <li>
                   Une salle pouvant accueillir 40 couverts en rez
  de chaussée et 26 en mezzanine.
                 </li>

<li>
C’est également un bar pour mettre en valeur les boissons emblématiques de la
Normandie.
</li>

<li>
  Confortable, intimiste à la convivialité et au
  design scandinave.
</li>

<li>Une atmosphère speakeasy, arty et trendy.</li>
</ul>
                 </div>
               </div>
             </div>
           </div>
          {/*<div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
             <p><Link to="/nos-projets" className="bouton">Voir les projets&nbsp;&nbsp;&gt;</Link></p>
           </div>*/} 
           <div className="lazyload" id="parallax1" data-bg="https://static.mediarestauration.fr/img/parallax2-nos-projets-hamlet.jpg">
           </div>
         </div>
           </div>
           </>
    );
}
