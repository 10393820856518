import React, { Suspense } from "react";
import "./nosReferences.css";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Helmet } from "react-helmet";
import RefUrl from "./utilitaires/refurl";
import MaitreRestaurateur from "./utilitaires/maitrerestaurateur";
import Routard from "./utilitaires/routard";
import Michelin from "./utilitaires/michelin";
import RefTitle from "./utilitaires/reftitre";
const GaleriePhotoGcb = React.lazy(() =>
  import("../utilitaires/Galeriephotogcb")
);
const GaleriePhotoLP = React.lazy(() =>
  import("../utilitaires/GaleriephotoLP")
);
const GaleriePhotoBP = React.lazy(() =>
  import("../utilitaires/GaleriephotoBP")
);

export default function NosReferencese() {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  }, []);
  return (
    <>
      <Helmet>
        {/*<!-- Primary Meta Tags -->*/}
        <title>
          Nos références - Media Restauration France - Consultant professionnel
        </title>
        <meta
          name="title"
          content="Nos références -  Media Restauration France - Consultant professionnel"
        />
        <meta
          name="description"
          content="Découvrez maintenant nos références, les projets menés à bien par MEDIA Restauration, consultant pour tous vos projets de fooding."
        />

        {/*<!-- Open Graph / Facebook -->*/}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.mediarestauration.fr/nos-references"
        />
        <meta
          property="og:title"
          content="Nos références -  Media Restauration France - Consultant professionnel"
        />
        <meta
          property="og:description"
          content="Découvrez maintenant nos références, les projets menés à bien par MEDIA Restauration, consultant pour tous vos projets de fooding."
        />
        <meta
          property="og:image"
          content="https://www.mediarestauration.fr/fond-references-partager.png"
        />

        {/*<!-- Twitter -->*/}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.mediarestauration.fr/nos-references"
        />
        <meta
          property="twitter:title"
          content="Nos références -  Media Restauration France - Consultant professionnel"
        />
        <meta
          property="twitter:description"
          content="Découvrez maintenant nos références, les projets menés à bien par MEDIA Restauration, consultant pour tous vos projets de fooding."
        />
        <meta
          property="twitter:image"
          content="https://www.mediarestauration.fr/fond-references-partager.png"
        />
      </Helmet>
      <div id="nosReferences">
        <div id="page">
          <div className="entete">
            <div className="container-fluid ">
              <div className="col-12">
                <div className="row flex-sm-row flex-lg-row g-0">
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                    <h1 className="pb-lg-3 pt-2">MEDIA Restauration France</h1>
                    <div className="entete__bordure">
                      <h2 className="pt-3 pt-sm-0 d-block d-sm-block d-md-none focus-text-entrant">
                        Découvrez ici nos références
                      </h2>
                      <h2 className="pt-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">
                        Découvrez en détails toutes nos références
                      </h2>
                      <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">
                        Un projet, une histoire, un succès
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex inter espace pt-3 pt-xl-5">
            <div className="row g-0 align-content-center w-100 mt-0">
              <RefTitle
                lgTitle={"Gill Côté Bistro,"}
                lgTitle_secondline={"bistro & simplicité"}
                xlTitle={"Gill Côté Bistro"}
                xlTitle_secondline={"bistro & modernité"}
                xlSlogan={"Un mot d'ordre : simplicité"}
              />
            </div>
          </div>

          <div className="container-fluid pb-2 fond-blanc">
            <div className="col-xs-12 col-xxl-10 mx-auto">
              <div className="row justify-content-center mx-auto">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-4 mb-5 mb-sm-0 align-self-center">
                  <p className="text-justify">
                    Niché au coeur de{" "}
                    <span className="couleur">
                      <strong>Rouen</strong>
                    </span>
                    , Gill Côté Bistro c'est un bistro comme on les aime,
                    classique mais morderne, petit mais chaleureux, chic mais
                    simple. Le{" "}
                    <span className="couleur">
                      <strong>concept pour la réalisation</strong>
                    </span>{" "}
                    de ce bistro : y retrouver tout ce qui fait l'âme d'un bon
                    bistro avec un seul mot d'ordre :{" "}
                    <span className="couleur">
                      <strong>simplicité</strong>
                    </span>
                    .{" "}
                  </p>
                  <p className="text-center">
                    <span className="d-flex justify-content-center p-2 justify-content-around">
                      <Michelin />
                      <Routard />
                    </span>
                  </p>
                </div>
                <div className="col-xs-3 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-4 mb-5 align-self-center mb-sm-0">
                  <Suspense fallback={<div>Chargement...</div>}>
                    <GaleriePhotoGcb></GaleriePhotoGcb>
                  </Suspense>
                </div>
              </div>
            </div>
            <RefUrl url={"https://www.gill-cote-bistro.fr"} />
          </div>
          <div
            className="lazyload"
            id="parallax1"
            data-bg="https://static.mediarestauration.fr/img/gcb/fond_gill.jpg"
          ></div>
          <div className="container-fluid">
            <RefTitle
              lgTitle={"La Place,"}
              lgTitle_secondline={"table néo-brasserie"}
              xlTitle={"La Place"}
              xlTitle_secondline={"brasserie & tendance"}
              xlSlogan={"Une table néo-brasserie signée Gilles Tournadre"}
            />
            <div className="col-xs-12 col-xxl-10 mx-auto">
              <div className="row justify-content-center mx-auto">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-4 mb-5 mb-sm-0 align-self-center order-1 order-lg-2">
                  <p className="text-justify">
                    <span className="couleur">
                      <strong>La Place</strong>
                    </span>
                    , une brasserie du centre ville de Rouen composée d'une
                    équipe de cuisine formée par{" "}
                    <span className="couleur">
                      <strong>
                        Gilles Tournadre & son second : Damien Duboc
                      </strong>
                    </span>
                    . Le concept pour la réalisation de cette brasserie :
                    proposer des plats de saison,{" "}
                    <span className="couleur">
                      <strong>pour tous</strong>
                    </span>
                    , petits et grands et pouvoir accueillir de grands groupes.
                  </p>
                  <p className="text-center">
                    <span className="d-flex justify-content-center p-2 justify-content-around">
                      <Routard />
                      <Michelin />
                    </span>
                  </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xxl-5 mb-5 align-self-center mb-sm-0 order-2 order-lg-1">
                  <Suspense fallback={<div>Chargement...</div>}>
                    <GaleriePhotoLP></GaleriePhotoLP>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          <RefUrl url={"https://www.laplace-restaurant-brasserie.fr"} />
          <div
            className="lazyload"
            id="parallax1"
            data-bg="https://static.mediarestauration.fr/img/lp/fond_lp.jpg"
          ></div>
          <div className="container-fluid">
            <RefTitle
              lgTitle={"La Brasserie Paul,"}
              lgTitle_secondline={"La plus ancienne brasserie de Rouen"}
              xlTitle={"La Brasserie Paul"}
              xlTitle_secondline={"histoire & convivialité"}
              xlSlogan={
                "La plus ancienne brasserie de Rouen encore en activité"
              }
            />
            <div className="col-xs-12 col-xxl-10 mx-auto">
              <div className="row justify-content-center mx-auto">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-4 mb-5 mb-sm-0 align-self-center ml-xl-5 bp">
                  <p className="text-justify">
                    <span className="couleur">
                      <strong>La Brasserie Paul</strong>
                    </span>
                    , ou la brasserie qui a connu le passage des célèbres et
                    renommés{" "}
                    <span className="couleur">
                      <strong>Simone de Beauvoir</strong>
                    </span>
                    ,{" "}
                    <span className="couleur">
                      <strong>Philippe Delerm</strong>
                    </span>{" "}
                    ou encore{" "}
                    <span className="couleur">
                      <strong>Marcel Duchamp</strong>
                    </span>
                    . Le concept pour la réalisation de cette brasserie :
                    continuer à proposer{" "}
                    <span className="couleur">
                      <strong>des plats de qualité</strong>
                    </span>
                    , comme c'est le cas depuis 1898 et rénover tout le
                    restaurant. ​
                  </p>
                  <p className="text-center">
                    <span className="d-flex justify-content-center p-2 justify-content-around">
                      <Routard />
                      <MaitreRestaurateur />
                    </span>
                  </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xxl-5 mb-5 align-self-center mb-sm-0">
                  <Suspense fallback={<div>Chargement...</div>}>
                    <GaleriePhotoBP></GaleriePhotoBP>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
          <RefUrl url={"https://www.brasserie-paul.com"} />
        </div>
      </div>
    </>
  );
}
