import React from "react";

export default function Michelin(props) {
  return (
    <img
      data-src="https://res.cloudinary.com/zharkan/image/upload/f_auto,q_auto/v1611159281/ressources-communes/michelin2021.png"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      alt="guide michelin"
      width="20%"
      className="img-fluid routard lazyload"
    />
  );
}
