import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

export default function Accueil() {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  }, []);
  return (
    <>
      <Helmet>
        {/*<!-- Primary Meta Tags -->*/}
        <title>
          Media Restauration France - Consultant professionnel - Lancez-vous
          maintenant - Philippe Coudy
        </title>
        <meta
          name="title"
          content="Media Restauration France - Consultant professionnel - Lancez-vous maintenant - Philippe Coudy"
        />
        <meta
          name="description"
          content="Depuis plus de 30 ans MEDIA Restauration donne vie aux grands projets de Restauration et de Fooding. Un projet, une idée ? On peut vous aider. Un consulting adapté à tous vos besoins."
        />

        {/*<!-- Open Graph / Facebook -->*/}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mediarestauration.fr/" />
        <meta
          property="og:title"
          content="Media Restauration France - Consultant professionnel - Lancez-vous maintenant - Philippe Coudy"
        />
        <meta
          property="og:description"
          content="Depuis plus de 30 ans MEDIA Restauration donne vie aux grands projets de Restauration et de Fooding. Un projet, une idée ? On peut vous aider. Un consulting adapté à tous vos besoins."
        />
        <meta
          property="og:image"
          content="https://www.mediarestauration.fr/fond-link-partager.png"
        />

        {/*<!-- Twitter -->*/}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.mediarestauration.fr/"
        />
        <meta
          property="twitter:title"
          content="Media Restauration France - Consultant professionnel - Lancez-vous maintenant - Philippe Coudy"
        />
        <meta
          property="twitter:description"
          content="Depuis plus de 30 ans MEDIA Restauration donne vie aux grands projets de Restauration et de Fooding. Un projet, une idée ? On peut vous aider. Un consulting adapté à tous vos besoins."
        />
        <meta
          property="twitter:image"
          content="https://www.mediarestauration.fr/fond-link-partager.png"
        />
      </Helmet>
      <div id="index">
        <div id="page">
          <div className="overflow-hidden">
            <div className="fix__pour__safari">
              <div className="entete">
                <div className="container-fluid">
                  <div className="col-12">
                    <div className="row flex-sm-row flex-lg-row g-0">
                      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xl-4 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                        <div className="entete__bordure">
                          <h1 className="pb-lg-3 pt-3 pt-sm-0 pt-md-5">
                            MEDIA Restauration France
                          </h1>
                          <h2 className="pt-3 pb-3 pt-sm-0 pb-sm-0 d-block d-sm-block d-md-none focus-text-entrant">
                            Vous Conseille et vous Aide dans la gestion de votre
                            restaurant ou de votre projet gastronomique
                          </h2>
                          <h2 className="pt-3 pb-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">
                            Vous Conseille et vous Aide dans la gestion de votre
                            restaurant ou de votre projet gastronomique
                          </h2>
                        </div>
                        <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">
                          Conseil Gestion Innovation Rentabilité Expertise
                          Financement Performance
                        </h3>
                      </div>
                      <div className="mt-5 col-xs-12 col-sm-5 col-md-5 col-lg-4 col-xl-3 col-xxl-2 mx-auto align-self-center">
                        <NavLink to="/nos-services" className="bouton">
                          En savoir plus&nbsp;&nbsp;&gt;
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entete__zoom"></div>
            </div>
          </div>

          <div className="container-fluid fond-gris espace anti__entete">
            <div className="row mb-5">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
                  <Slide top>
                    <h5 className="text-center">Nos services</h5>
                  </Slide>
                </span>
                <Fade cascade>
                  <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                    <div className="row d-flex entete__bordure">
                      <div className="col-4 align-self-center">
                        <h6 className="couleur">
                          <em>Des solutions adaptées à tous vos besoins</em>
                        </h6>
                      </div>
                      <div className="col-8 align-self-center">
                        <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
                          Nos services{" "}
                          <span className="d-block couleur">
                            <strong>pour vous</strong>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-10 col-xxl-10 pt-5 mx-auto">
              <div className="row flex-lg-row text-center justify-content-center">
                <Slide top cascade>
                  <div className="col-xs-3 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 align-self-center">
                    <img
                      data-src="https://static.mediarestauration.fr/img/presence-nationale-regionale-light.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Logo présence nationale"
                      width="25%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/presence-nationale-regionale-light.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Logo présence nationale"
                      width="22%"
                    />
                    <p>
                      Une présence locale au{" "}
                      <span className="couleur">
                        <strong>
                          coeur de la{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version={1.0}
                            width="115.6"
                            height="23.4"
                            viewBox="0 0 492.6 65.9"
                          >
                            <g fill="#009ba8">
                              <path d="M71-7l-7 2c-6 3-6 6-6 25 0 21 0 23 7 27 4 2 5 2 16 2 10 0 13 0 16-2 8-4 8-5 8-27-1-21-1-22-8-26-4-2-20-3-26-1zM86 7c2 2 3 2 3 14 0 11-1 13-2 14-3 1-10 1-11-1l-1-14c0-12 0-13 2-13 3-2 7-2 9 0zM30 4v11l8 10 8 10V-7H30zM117 21v28h16v-8-10l6-1c9 0 10 1 10 11v8h16V36c0-11-1-12-3-14l-4-4c-2 0-2 0 0-2 1-2 2-4 1-10 0-12-3-13-27-13h-15zm25-14c2 2 2 8 0 10-3 1-8 1-9-1V6c1-1 8-1 9 1zM176-6c0 2 25 35 27 35l5-5 5-5 1 15v15h16V-7h-15l-6 8-6 8-7-8-6-8h-7l-7 1zM253-6l-6 14-4 12v29h15V35h16v14h16V22l-5-14-7-15c-2-1-24-1-25 1zm14 13l3 7 2 6c1 1-1 2-6 2l-7-1 2-6c2-8 3-9 6-8zM334 5v11l8 10 8 10V-6h-16zM364 21v28l18-1c28 0 29-1 29-27 0-27-1-28-29-28l-18-1zm28-14c1 1 2 4 1 14v13h-7l-6 1V7h12zM423-6c0 1 3 7 8 12l8 11V-7h-8l-8 1zM452-6v54h20l21 1V35h-24l-1-3c0-5 0-5 10-5h9V14h-18V7h24V-7h-20l-21 1zM0 22v24h16V19L8 9 0-2zM303 24v23h16V21l-7-9-8-11-1 23z" />
                            </g>
                            <path
                              d="M1-6L0-2v3l7 10a6621 6621 0 0129 35h9l1-6v-5l-8-11L21 4 14-6H1z"
                              fill="#7fba27"
                            />
                            <path
                              d="M176 21v28h17V17l-9-12-8-12v28zM304-5l-1 4v3l14 18 18 23 5 5h9l1-7v-6l-17-20-16-21h-6l-7 1zM423 20v26h15a526 526 0 00-2-34l-8-11-5-8z"
                              fill="#80ba27"
                            />
                          </svg>
                        </strong>
                      </span>{" "}
                      à Rouen. Une vocation nationale et internationale
                    </p>
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 align-self-start">
                    <img
                      data-src="https://static.mediarestauration.fr/img/innovation-idee.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Picto symbolisant l'innovation"
                      width="22%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/innovation-idee.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Picto symbolisant l'innovation"
                      width="32%"
                    />
                    <p>
                      Une création de concept de restauration à la fois{" "}
                      <span className="couleur">
                        <strong>innovante &amp; efficace</strong>
                      </span>
                    </p>
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-4 col-xl-2 align-items-end"></div>
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 align-self-start">
                    <img
                      data-src="https://static.mediarestauration.fr/img/consulting-projets.new.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Picto symbolisant le consulting"
                      width="20%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/consulting-projets.new.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Picto symbolisant le consulting"
                      width="22%"
                    />
                    <p>
                      Un consulting pour atteindre vos{" "}
                      <span className="couleur">
                        <strong>objectifs</strong>
                      </span>{" "}
                      et{" "}
                      <span className="couleur">
                        <strong>booster</strong>
                      </span>{" "}
                      votre affaire
                    </p>
                  </div>
                </Slide>
              </div>
            </div>

            <Fade>
              <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                <p>
                  <NavLink to="/nos-services" className="bouton">
                    Consulter nos services&nbsp;&nbsp;&gt;
                  </NavLink>
                </p>
              </div>
            </Fade>
          </div>
          <div
            className="container-fluid lazyload"
            id="fond-historique"
            data-bg="https://static.mediarestauration.fr/img/fond-historiquev3.jpg"
          >
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
              <div className="col-3 offset-xs-1 d-block d-md-block d-sm-block d-lg-block d-xl-none d-xxl-none pb-5 mb-3 ml-3">
                <div id="timeline-wrap">
                  <div id="timeline" />
                  {/* This is the individual marker*/}
                  <div className="marker mfirst timeline-icon one">1990</div>
                  {/* / marker */}
                  {/* This is the individual marker*/}
                  <Slide left>
                    <div className="marker mlast timeline-icon four">2021</div>
                  </Slide>
                  {/* / marker */}
                </div>
              </div>
              <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                <div className="row d-flex entete__bordure">
                  <div className="col-3 align-self-center">
                    <div id="timeline-wrap">
                      <div id="timeline" />
                      {/* This is the individual marker*/}
                      <div className="marker mfirst timeline-icon one">
                        1990
                      </div>
                      {/* / marker */}
                      {/* This is the individual marker*/}
                      <Slide left>
                        <div className="marker mlast timeline-icon four">
                          2021
                        </div>
                      </Slide>
                      {/* / marker */}
                    </div>
                  </div>
                  <div className="col-9 entete__historique">
                    <Fade>
                      <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
                        L'histoire derrière{" "}
                        <span className="d-block pl-5 couleur">
                          Media Restauration
                        </span>
                      </h5>
                    </Fade>
                  </div>
                </div>
              </div>

              <Fade>
                <h5 className="text-center d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
                  L'histoire derrière{" "}
                  <span className="d-block couleur">Media Restauration</span>
                </h5>
              </Fade>
            </div>
            <div className="row pt-1 pt-xl-5">
              <Fade>
                <div className="d-none d-sm-none d-md-block col-md-6 col-lg-5 col-xl-5 offset-xl-1 col-xxl-5 mt-5 pt-5 align-self-center">
                  <img
                    data-src="https://static.mediarestauration.fr/img/gros-horloge-image-min.jpg"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    background="transparent"
                    alt="Photo du gros horloge"
                    className="lazyload img-fluid d-none d-sm-none d-md-block d-lg-block d-xl-none d-xxl-none"
                  />

                  <div className="row flex-lg-row">
                    <div className="col-md-10 col-lg-7 col-xl-7 col-xxl-7 bordure align-self-center">
                      <img
                        data-src="https://static.mediarestauration.fr/img/gros-horloge-image-min.jpg"
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        background="transparent"
                        alt="Photo du gros horloge"
                        className="lazyload img-fluid d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block"
                      />
                    </div>

                    <div className="col-lg-4 col-xl-5 col-xxl-3 offset-xxl-2 align-self-center">
                      <img
                        data-src="https://static.mediarestauration.fr/img/fleche-vers-la-droite.svg"
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        alt="Flêche vers la droite"
                        className="lazyload d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block w-100 ml-xl-5 ml-xxl-0"
                        alt="Flêche vers la droite"
                      />
                    </div>
                  </div>
                </div>
              </Fade>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 mt-3 pt-5 align-self-center mr-xxl-3 bordure__historique">
                <h6 className="text-center pb-3">
                  Une grande expertise au service de tous vos projets
                </h6>
                <img
                  data-src="https://static.mediarestauration.fr/img/gros-horloge-image-min.jpg"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  background="transparent"
                  alt="Image du gros horloge"
                  className="lazyload img-fluid d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none"
                />
                <p className="text-justify mt-5 mt-lg-3">
                  Depuis{" "}
                  <span className="couleur">
                    <strong>plus de 30 ans</strong>
                  </span>{" "}
                  MEDIA Restauration donne vie aux grands projets de
                  Restauration et de Fooding. Une expérience et une{" "}
                  <span className="couleur">
                    <strong>équipe de professionnels exigeants</strong>
                  </span>{" "}
                  au service de vos ambitions et toujours à l'affut de{" "}
                  <span className="couleur">
                    <strong>solutions innovantes</strong>
                  </span>
                  .
                </p>
              </div>
            </div>
            <Fade>
              <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                <p>
                  <NavLink to="/historique" className="bouton">
                    En savoir plus&nbsp;&nbsp;&gt;
                  </NavLink>
                </p>
              </div>
            </Fade>
          </div>
          <div
            className="container-fluid lazyload"
            id="fond-reference"
            data-bg="https://static.mediarestauration.fr/img/fond-reference.png"
          >
            <div className="row mb-5">
              <Fade>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                  <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
                    <h5 className="text-center">
                      Une expérience{" "}
                      <span className="d-block couleur">de terrain</span>
                    </h5>
                  </span>
                  <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                    <div className="row d-flex entete__bordure">
                      <div className="col-4 align-self-center">
                        <h6 className="couleur">
                          <em>Quelques projets déjà concrétisés</em>
                        </h6>
                      </div>
                      <div className="col-8 align-self-center">
                        <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
                          Une expérience{" "}
                          <span className="d-block couleur">
                            <strong>sur le terrain</strong>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <p className="text-justify mt-5 mt-lg-5">
                    MEDIA Restauration France a su s'imposer comme un acteur
                    incontournable de la restauration et de la gestion
                    d'établissements au fil des années grâce à son expérience au
                    quotidien, sur le terrain. Au cours de ces nombreuses
                    années, nous avons su moderniser, adapter ces différents
                    établissements aux besoins du marché.
                  </p>
                </div>
              </Fade>
            </div>
            <Fade>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 pt-5 p-1 mx-auto overflow-hidden">
                <div className="row flex-lg-row text-center justify-content-center">
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 align-self-start mb-5 mb-sm-0 ombre-portee-centre">
                    <img
                      data-src="https://static.mediarestauration.fr/img/gcb-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Logo Gill Côté Bistro"
                      width="28%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/gcb-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Logo Gill Côté Bistro"
                      width="25%"
                    />
                    <p className="mt-3">
                      Gill Côté Bistro, un mot d'ordre :{" "}
                      <span className="couleur">
                        <strong>Simplicité</strong>
                      </span>
                    </p>
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mb-5 mb-sm-0 ombre-portee-centre align-self-start">
                    <img
                      data-src="https://static.mediarestauration.fr/img/brasserie-paul-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Logo Brasserie Paul"
                      width="38%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/brasserie-paul-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Logo Brasserie Paul"
                      width="65%"
                    />
                    <p className="mt-3">
                      La Brasserie Paul, la plus ancienne brasserie de Rouen
                      encore en activité et{" "}
                      <span className="couleur">
                        <strong>membre des Maîtres Restaurateurs</strong>
                      </span>
                    </p>
                    <img
                      data-src="https://static.mediarestauration.fr/img/maitre-restaurateur.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      alt="maitre restaurateur"
                      width="40%"
                      className="lazyload"
                    />
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-4 col-xl-2 align-items-end"></div>
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 align-self-start mt-5 mt-sm-0 ombre-portee-centre">
                    <img
                      data-src="https://static.mediarestauration.fr/img/la-place-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                      alt="Logo La Place"
                      width="40%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/la-place-logo.svg"
                      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                      alt="Logo La Place"
                      width="25%"
                    />
                    <p className="mt-3">
                      La Place, La table néo brasserie de{" "}
                      <span className="couleur">
                        <strong>Gilles Tournadre</strong>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-xs-3 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mx-auto mt-5 text-center ombre-portee-centre">
                  <img
                    data-src="https://static.mediarestauration.fr/img/golf-vaudreuil-logo.png"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mx-auto img-fluid"
                    alt="Logo Golf Vaudreuil"
                    width="25%"
                  />
                  <img
                    data-src="https://static.mediarestauration.fr/img/golf-vaudreuil-logo.png"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none img-fluid mx-auto"
                    alt="Logo Golf Vaudreuil"
                    width="20%"
                  />
                  <p className="mt-3">
                    Le golf du Vaudreuil :{" "}
                    <span className="couleur">
                      <strong>
                        Assistance technique et commerciale pour lancement
                        activité restauration
                      </strong>
                    </span>
                  </p>
                </div>
              </div>
            </Fade>

            <Fade>
              <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                <p>
                  <NavLink to="/nos-references" className="bouton">
                    Découvrir nos références&nbsp;&nbsp;&gt;
                  </NavLink>
                </p>
              </div>
            </Fade>
          </div>
          <div className="container-fluid espace g-0 overflow-hidden pl-0 pr-0">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none">
                  <h5 className="text-center">
                    Les prochains{" "}
                    <span className="d-block couleur">projets...</span>
                  </h5>
                </span>
                <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                  <div className="row d-flex entete__bordure">
                    <Fade cascade>
                      <div className="col-4 align-self-center">
                        <h6 className="couleur">
                          <em>Les projets en cours de création</em>
                        </h6>
                      </div>

                      <div className="col-8 align-self-center">
                        <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
                          Les prochains{" "}
                          <span className="d-block couleur">
                            <strong>projets...</strong>
                          </span>
                        </h5>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <Fade cascade>
              <div
                className=" lazyload"
                id="fond-projets"
                data-bg="https://static.mediarestauration.fr/img/fond-projets.png"
              >
                <div className="row text-center justify-content-center mx-auto pt-5 mb-5">
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-4 col-xl-5 col-xxl-5 mb-5 align-self-center mb-sm-0 ombre-portee-centre">
                    <img
                      data-src="https://static.mediarestauration.fr/img/cafe-hamlet-logo.svg"
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block ml-auto img-fluid"
                      alt="Logo Café Hamlet"
                      width="30%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/cafe-hamlet-logo.svg"
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none mx-auto img-fluid"
                      alt="Logo Café Hamlet"
                      width="40%"
                    />
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-5 col-xl-6 mr-auto col-xxl-3 mb-5 mb-sm-0">
                    <p className="mt-3">
                      Le café Hamlet niché dans le site historique et
                      patrimonial de l'Aître Saint maclou :{" "}
                      <span className="couleur">
                        <strong>un snacking chic signé Gilles Tournadre</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="row text-center justify-content-center mx-auto pt-5 mt-5">
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-5 col-xl-6 ml-auto col-xxl-4 mb-5 mb-sm-0">
                    <p className="mt-3">
                      Boujou, ou l'excellence de la gastronomie et des produits
                      Normands{" "}
                      <span className="couleur">
                        <strong>exportée partout dans le monde !</strong>
                      </span>
                    </p>
                  </div>
                  <div className="col-xs-3 col-sm-12 col-md-4 col-lg-4 col-xl-5 col-xxl-5 mb-5 align-self-center mb-sm-0 ombre-portee-centre">
                    <img
                      data-src="https://static.mediarestauration.fr/img/boujou-logo.svg"
                      className="lazyload d-none d-sm-none d-md-block d-lg-block d-xl-block mr-auto img-fluid"
                      alt="logo boujou"
                      width="30%"
                    />
                    <img
                      data-src="https://static.mediarestauration.fr/img/boujou-logo.svg"
                      className="lazyload d-block d-sm-block d-md-none d-lg-none d-xl-none mx-auto img-fluid"
                      alt="logo boujou"
                      width="25%"
                    />
                  </div>
                </div>
                <Fade>
                  <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                    <p>
                      <NavLink to="/nos-projets" className="bouton">
                        Voir les projets&nbsp;&nbsp;&gt;
                      </NavLink>
                    </p>
                  </div>
                </Fade>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
