import React from 'react';
import Slide from 'react-reveal/Slide';

import { NavLink } from 'react-router-dom';

export default function Navigation () {
    return (
<div>
<Slide top cascade>
       <div className="topnav" id="barreDesktop">
               <NavLink to="/" className="navbar__pas-de-hover navbar__fixe"><img src="https://static.mediarestauration.fr/img/logo-navbar-itc.svg" alt="Logo Media Restauration svg" width="img-responsive" /></NavLink>
               <NavLink to="/nos-services">Nos services</NavLink>
               <NavLink to="/nos-references">Nos références</NavLink>
               <NavLink to="/nos-projets">Nos projets</NavLink>
               <NavLink to="/historique">Historique</NavLink>
               <NavLink to="contactez-nous">Contactez-nous</NavLink>
             </div>
             </Slide>

        <div id="laBarredeNav" className="sidenav">
          <a className="closebtn" id="barreMobile">×</a>
          <NavLink to="/" className="navbar__pas-de-hover navbar__fixe pb-5 pb-sm-0 lienMobile" ><img src="https://static.mediarestauration.fr/img/logo-mobile.svg" className="bordure_mobile" alt="Logo Media Restauration svg lienMobile" width="img-responsive" /></NavLink>
          <div className="d-flex flex-column flex-sm-row flex-md-column flex bd-highlight justify-content-center pb-5 pb-sm-0 ">

            <div className="p-sm-2 p-md-0 bd-highlight"><NavLink to="/nos-services"className="pt-0 pt-sm-5 pt-md-3 lienMobile">Nos services</NavLink></div>
            <div className="p-sm-2 p-md-0 bd-highlight" ><NavLink to="/nos-references"className="pt-0 pt-sm-5 pt-md-3 lienMobile">Nos références</NavLink></div>
            <div className="p-sm-2 p-md-0 bd-highlight" ><NavLink to="nos-projets" className="pt-0 pt-sm-5 pt-md-3 lienMobile">Nos projets</NavLink></div>
            <div className="p-sm-2 p-md-0 bd-highlight"><NavLink to="/historique"className="pt-0 pt-sm-5 pt-md-3 lienMobile">Historique</NavLink></div>
            <div className="p-sm-2 p-md-0 bd-highlight" ><NavLink to="/contactez-nous" className="pt-0 pt-sm-5 pt-md-3 lienMobile">Contactez-nous</NavLink></div>
          </div>
<div className="pt-3">          <p>26 place du Vieux Marché</p>
          <p>76000 Rouen</p>
          <p>02 35 08 54 79</p>
          <p>contact@mediarestauration.fr</p></div>
        </div>
        
        <div id="barre-de-navigation">
          <div className="nav-mobile logo-centre fix__pour__safari_navmobile">
            <span className="navbar__bout-a-droite" style={{fontSize: '30px', cursor: 'pointer'}} id="ouvertureNav">☰</span>
            <NavLink to="/"><img src="https://static.mediarestauration.fr/img/logo-navbar-itc.svg" className="img-responsive lienMobile" alt="Logo Media Restauration svg" /></NavLink>
          </div>
        </div>
        </div>
    );
}
