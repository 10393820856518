import React from "react";

export default function RefTitle(props) {
  return (
  <div className="col-12 d-flex inter">
<div className="row g-0 align-content-center w-100 mt-5 mb-5">
  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
    <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
      <h5 className="text-center">
        {props.lgTitle}
        <span className="d-block couleur">
          {props.lgTitle_secondline}
        </span>
      </h5>
    </span>
    <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
      <div className="row d-flex entete__bordure fond_texte">
        <div className="col-4 align-self-center">
          <h6 className="couleur">
            <em>
              {props.xlSlogan}
            </em>
          </h6>
        </div>
        <div className="col-8 align-self-center">
          <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">
            {props.xlTitle}
            <span className="d-block couleur">
              <strong>{props.xlTitle_secondline}</strong>
            </span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
 );
}