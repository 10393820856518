import * as React from "react";
import './nosServices.css';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function NosServices() {
  const { trackPageView } = useMatomo()
React.useEffect(() => {
trackPageView()
}, [])
    return (
      <>
       <Helmet>
      {/*<!-- Primary Meta Tags -->*/}
<title>Découvrez nos services maintenant -  Media Restauration France - Consultant professionnel</title>
<meta name="title" content="Découvrez nos services maintenant -  Media Restauration France - Consultant professionnel"/>
<meta name="description" content="Ouvrir votre restaurant, obtenir de l'aide pour la gestion  MEDIA Restauration, consultant pour tous vos projets de fooding à Rouen peut vous aider."/>

{/*<!-- Open Graph / Facebook -->*/}
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.mediarestauration.fr/nos-services"/>
<meta property="og:title" content="Découvrez nos services maintenant -  Media Restauration France - Consultant professionnel"/>
<meta property="og:description" content="Ouvrir votre restaurant, obtenir de l'aide pour la gestion  MEDIA Restauration, consultant pour tous vos projets de fooding à Rouen peut vous aider."/>
<meta property="og:image" content="https://www.mediarestauration.fr/fond-service-partager.png"/>

{/*<!-- Twitter -->*/}
<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://www.mediarestauration.fr/nos-services"/>
<meta property="twitter:title" content="Découvrez nos services maintenant -  Media Restauration France - Consultant professionnel"/>
<meta property="twitter:description" content="Ouvrir votre restaurant, obtenir de l'aide pour la gestion  MEDIA Restauration, consultant pour tous vos projets de fooding à Rouen peut vous aider."/>
<meta property="twitter:image" content="https://www.mediarestauration.fr/fond-service-partager.png"/>

      </Helmet>
      <div id="nosServices">
 
      <div id="page">
             <div className="entete">
               <div className="container-fluid ">
                 <div className="col-12">
                   <div className="row flex-sm-row flex-lg-row g-0">
                     <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                       <h1 className="pb-lg-3 pt-2">MEDIA Restauration France</h1>
                       <div className="entete__bordure">
                         <h2 className="pt-3 pt-sm-0 d-block d-sm-block d-md-none focus-text-entrant">Découvrez en détails tous nos services</h2>
                         <h2 className="pt-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">Découvrez en détails tous nos services</h2>
                         <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">Un savoir faire, un esprit, une Aventure</h3>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="col-12 d-flex inter espace pt-5">
               <div className="row g-0 align-content-center w-100 mt-5 mb-5">
                 <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                   <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                     <h5 className="text-center">Une présence <span className="d-block couleur">locale &amp; nationale</span></h5>
                   </span>
                   <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                     <div className="row d-flex entete__bordure fond_texte">
                       <div className="col-4 align-self-center">
                         <h6 className="couleur"><em>& internationale sur tous nos services</em></h6>
                       </div>
                       <div className="col-8 align-self-center">
                         <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">Une présence <span className="d-block couleur"><strong>locale &amp; nationale</strong></span></h5>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>


             <div className="container-fluid pt-5 pb-5 fond-blanc">
               <div className="col-12 mx-auto">
                 <div className="row justify-content-center mx-auto">
                   <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6 mx-atuo mb-5 mb-sm-0 align-self-center">
                     <p className="text-justify">Installé au cœur de la <span className="couleur"><strong>Normandie</strong></span>, MEDIA Restauration a concentré ses moyens techniques et humains à Rouen, offrant alors une grande
                       disponibilité à nos clients : suivi et assistance pour toute la durée des missions, que ce soit
                       pour <span className="couleur"><strong>la création de concept CHR autant que l'assistance à la gestion </strong></span>
                       de votre établissement. </p>
                   </div>
                   <div className="col-xs-3 col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-3 mb-5 align-self-center mb-sm-0">
                     <div className="col-9 mx-auto ombre p-3 fond-gris bordure_haut-row bordure_gauche-row bordure_droite-row bordure_haut-row">
                       <div className="row flex-md-row text-center">
                         <div className="col-6 p-3 bordure_droite bordure_bas">
                           <a tabIndex={0} className="curseur" data-bs-trigger="focus" title="Suivi des tendances" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Suivi des dernières tendances du marché et des besoins de votre clientèle"> <img data-src="https://static.mediarestauration.fr/img/icones/suivi.svg" className="lazyload" alt="Accompagnement" width="65%" />
                             <span className="d-block">Tendance</span></a>
                         </div>
                         <div className="col-6 p-3 bordure_bas">
                           <a tabIndex={0} className="curseur" data-bs-trigger="focus" title="Toujours à l'affut" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Une équipe de professionnels toujours à l'affut de solutions innovantes et novatrices qui vous aideront au quotidien"><img data-src="https://static.mediarestauration.fr/img/icones/innovation.svg" className="lazyload" alt="Innovation" width="65%" />
                             <span className="d-block">Innovation</span></a>
                         </div>
                       </div>
                       <div className="row flex-md-row text-center">
                         <div className="col-6 p-3 bordure_droite">
                           <a tabIndex={0} className="curseur" data-bs-trigger="focus" title="Réputation en ligne" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Gestion de votre e-réputation, de votre présence sur internet et de vos résesaux socicaux"><img data-src="https://static.mediarestauration.fr/img/icones/e-reputation.svg" className="lazyload" alt="E-Réputation" width="65%" />
                             <span className="d-block">E-réputation</span></a>
                         </div>
                         <div className="col-6 p-3">
                           <a tabIndex={0} className="curseur" data-bs-trigger="focus" title="Des formations" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Un ensemble de formations sont disponibles pour vous aider au quotidien dans votre gestion"><img data-src="https://static.mediarestauration.fr/img/icones/formation.svg" className="lazyload" alt="Formation" width="65%" />
                             <span className="d-block">Formation</span></a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="lazyload" id="parallax1" data-bg="https://static.mediarestauration.fr/img/parallax1-nos-services-min.jpg">
             </div>
             <div className="container-fluid">
               <div className="col-12 d-flex inter">
                 <div className="row g-0 align-content-center w-100 mt-5 mb-5">
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                     <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                       <h5 className="text-center">Une création de <span className="d-block couleur">concepts de Restauration</span></h5>
                     </span>
                     <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                       <div className="row d-flex entete__bordure fond_texte">
                         <div className="col-4 align-self-center">
                           <h6 className="couleur"><em>Création de concepts de Restauration</em></h6>
                         </div>
                         <div className="col-8 align-self-center">
                           <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">Innovation <span className="d-block couleur"><strong>&amp; efficacité</strong></span></h5>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-12 mx-auto pb-5">
                 <div className="row justify-content-center mx-auto">
                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 mx-atuo mb-5 mb-sm-0 align-self-center">
                     <p className="text-justify">Nous vous accompagnons du <span className="couleur"><strong>montage de votre projet à la création de votre établissement</strong></span>.
                     Contrôle du risque et développement de l’innovation s’imposent tout autant que la fiabilité et la rentabilité des concepts crées. Forts de <span className="couleur"><strong>plus de 30 ans d'expérience</strong></span> maintenant
                       chez Media Restauration France (retrouvez <Link to="/nos-projets">nos projets ici</Link>), nous vous aiderons a bâtir les étapes clés à la réalisation de votre projet.
                       ​
                     </p>
                   </div>
                   <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xxl-3 mb-5 align-self-center mb-sm-0">
                     <ul className="liste">
                       <li>Mise en thème et cohérence (Bistro, Brasserie, traditionnel...)</li>
                       <li>Ergonomie cuisine / salle</li>
                       <li>Elaboration d'une offre restauration originale et rentable</li>
                       <li>Application des tendances de consommations en matière d'éthique et de développement durable</li>
                       <li>Communication et e-réputation</li>
                       <li>Pilotage et mise en œuvre de votre projet</li>
                       <li>Formation et déploiement de votre solution</li>
                       <li>Suivi de votre projet</li>
                     </ul>
                   </div>
                 </div>
               </div>
             </div>
             <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
               <p><Link to="/nos-projets" className="bouton">Voir les projets&nbsp;&nbsp;&gt;</Link></p>
             </div>
             <div className="lazyload" id="parallax1" data-bg="https://static.mediarestauration.fr/img/parallax2-nos-services-min.jpg">
             </div>
             <div className="container-fluid">
               <div className="col-12 d-flex inter pt-5">
                 <div className="row align-content-center w-100 mt-5 mb-5">
                   <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                     <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                       <h5 className="text-center">Un consulting pour <span className="d-block couleur">atteindre vos objectifs et booster votre affaire</span></h5>
                     </span>
                     <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                       <div className="row d-flex entete__bordure fond_texte">
                         <div className="col-4 align-self-center">
                           <h6 className="couleur"><em>Un consulting pour booster vos affaires</em></h6>
                         </div>
                         <div className="col-8 align-self-center">
                           <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">Atteindre <span className="d-block couleur"><strong>vos objectifs</strong></span></h5>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
               <div className="col-12 mx-auto pb-5">
                 <div className="row justify-content-center mx-auto">
                   <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 mx-atuo mb-5 mb-sm-0 align-self-center">
                     <p className="text-justify">
                       <span className="couleur">MEDIA Restauration</span> c'est aussi des conseils et une gestion pour les restaurants (<span className="couleur"><strong>stratégique, juridique ou financier</strong></span>) et un accompagnement
                       dans l'optimisation de votre établissement.
                     </p>
                   </div>
                   <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xxl-3 mb-5 mb-sm-0">
                     <ul className="liste">
                       <li>Cuisine et Service</li>
                       <li>Augmentation du CA</li>
                       <li>Gestion des stocks (inventaires, roulements...)</li>
                       <li>Normes HACCP</li>
                       <li>Visibilité, notoriété et communication</li>
                       <li>Contrôle et gestion (frais fixes, frais variables...)</li>
                       <li>Comptabilité et Finance</li>
                       <li>Ressources Humaines (recrutement, outils RH...)</li>
                     </ul>
                   </div>
                 </div>
               </div>
               <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
                 <p><Link to="/contactez-nous" className="bouton">Commencer maintenant&nbsp;&nbsp;&gt;</Link></p>
               </div>
             </div>
           </div>
           </div>
           </>
    );
}
