import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const instance = createInstance({
  urlBase: 'https://cpemael.avenir-resa.fr',
  siteId: 17,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})

ReactDOM.render(
  <React.StrictMode>
  <MatomoProvider value={instance}>
    <App />
    </MatomoProvider>,
  </React.StrictMode>,
  document.getElementById('root')
);

//On appelle l'ouverture de la barre de navigation
function ouvertureNav() {
  document.getElementById("laBarredeNav").style.width = "100%";
  document.getElementById("page").style.opacity = "0";
}
document.getElementById("barreDesktop").addEventListener("click", activerScrollDesktop, true);
function activerScrollDesktop(){
  document.body.style.overflow = 'unset';
}
document.getElementById("ouvertureNav").addEventListener("click", desactiverScroll, true);
document.getElementById("ouvertureNav").addEventListener("click", ouvertureNav, true);
document.getElementById("barreMobile").addEventListener("click", fermetureNav, true);
document.getElementById("barreMobile").addEventListener("click", activerScroll, true);
document.querySelectorAll('.lienMobile').forEach(item => {
  item.addEventListener('click', event => {
    fermetureNav();
    activerScroll();
    activerScrollDesktop();
  })
})
//On ferme la barre de navigation
function fermetureNav() {
  document.getElementById("laBarredeNav").style.width = "0";
  document.getElementById("page").style.opacity = "1";

}
//On définit les constantes
let scrollpos = window.scrollY
const lentete = document.querySelector(".nav-mobile")
const lentete_taille = lentete.offsetHeight

const ajouter_class_au_scroll = () => lentete.classList.add("fondu-entrant")
const retirer_class_au_scroll = () => lentete.classList.remove("fondu-entrant")

window.addEventListener('scroll', function() {
  scrollpos = window.scrollY;
  //Si le scroll démarre on change la couleur de la navbar MOBILE
  if (scrollpos >= lentete_taille) {
    ajouter_class_au_scroll()
  } else {
    retirer_class_au_scroll()
  }

})

//Ici on fait la même chose mais avec la barre de nav desktop

var barreDesktop = document.querySelector('.topnav');

window.onscroll = function() {
  onMontre();
};

function onMontre() {
  barreDesktop.classList.toggle("topnav_apres", barreDesktop.scrollTop > 50 || document.documentElement.scrollTop > 50);
}


// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {
  37: 1,
  38: 1,
  39: 1,
  40: 1
};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true;
    }
  }));
} catch (e) {}

var wheelOpt = supportsPassive ? {
  passive: false
} : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function desactiverScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function activerScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.unregister();