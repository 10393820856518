import React from 'react';
import './contactezNous.css';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from 'emailjs-com';
import {Helmet} from "react-helmet";


export default function ContactezNous() {
  const boutonEnvoyer = document.getElementById('envoi')
  const { trackPageView } = useMatomo()
  React.useEffect(() => {
  trackPageView()
}, [])

function sendEmail(e) {
  boutonEnvoyer.classList.add('disabled');
  boutonEnvoyer.innerHTML = "Veuillez patienter...";
  e.preventDefault();
  emailjs.sendForm('service_4nrwwxk', 'template_1m8nfql', e.target, 'user_yjISpibZKjv26xC7eCnQx')
  

    .then((result) => {
        console.log(result.text);
        document.getElementById("envoye").style.display = "block"
        document.getElementById("message").style.display = "none"

    }, (error) => {
        console.log(error.text);
    });
}

  const formik = useFormik({
    initialValues: {
      titre:"",
      name: "",
      societe: "",
      email: "",
      message: ""
    },
     validate(values) {
       const errors = {};

      if (!values.email) {
         errors.email = "Veuillez indiquer votre email";
      } else if (
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
       ) {
         errors.email = "Votre adresse n'est pas valide";
       }
       if (!values.message) {
        errors.message = "Veuillez indiquer la raison pour laquelle vous souhaitez nous joindre";
     }
     if (!values.societe) {
      errors.societe = "Veuillez indiquer le nom de votre société";
   }
     if (!values.name) {
      errors.name = "Veuillez indiquer votre nom";
   }
   if (!values.titre) {
    errors.titre = "Champ requis";
 }

       return errors;
     },
    validationSchema: Yup.object({
      titre: Yup.string().required(),
      name: Yup.string().required(),
      societe: Yup.string().required(),
      email: Yup.string()
        .email()
        .required(),
      message: Yup.string().required()
    }),
    onSubmit(values) {
      console.log(values);
      boutonEnvoyer.classList.add('disabled')
    }
  });

  return (
    <>
    <Helmet>
      {/*<!-- Primary Meta Tags -->*/}
<title>Contacter Media Restauration France - Consultant professionnel</title>
<meta name="title" content="Contacter Media Restauration France - Consultant professionnel"/>
<meta name="description" content="Un projet à mener à bien, une simple question à poser ? Nous intervenons sur toute la France, pour tous vos projets de fooding, un consulting adapté."/>

{/*<!-- Open Graph / Facebook -->*/}
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://www.mediarestauration.fr/contactez-nous"/>
<meta property="og:title" content="Contacter Media Restauration France - Consultant professionnel"/>
<meta property="og:description" content="Un projet à mener à bien, une simple question à poser ? Nous intervenons sur toute la France, pour tous vos projets de fooding, un consulting adapté."/>
<meta property="og:image" content="https://www.mediarestauration.fr/fond-contact-partagerv1.jpg"/>

{/*<!-- Twitter -->*/}
<meta property="twitter:card" content="summary_large_image"/>
<meta property="twitter:url" content="https://www.mediarestauration.fr/contactez-nous"/>
<meta property="twitter:title" content="Contacter Media Restauration France - Consultant professionnel"/>
<meta property="twitter:description" content="Un projet à mener à bien, une simple question à poser ? Nous intervenons sur toute la France, pour tous vos projets de fooding, un consulting adapté."/>
<meta property="twitter:image" content="https://www.mediarestauration.fr/fond-contact-partagerv1.jpg"/>

      </Helmet>
    <div id="contactezNous">
    <div id="page">
           <div className="entete">
             <div className="container-fluid ">
               <div className="col-12">
                 <div className="row flex-sm-row flex-lg-row g-0">
                   <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 ml-0 pl-0 ml-lg-3 col-xxl-4 pl-sm-2 pl-md-3 pt-0 pl-lg-5 mt-xl-5 align-self-center">
                     <h1 className="pb-lg-3 pt-2">MEDIA Restauration France</h1>
                     <div className="entete__bordure">
                       <h2 className="pt-3 pt-sm-0 d-block d-sm-block d-md-none focus-text-entrant">Prendre contact avec nous</h2>
                       <h2 className="pt-3 d-none d-sm-none d-md-block d-lg-block focus-text-entrant">Prêt à commencer ?</h2>
                       <h3 className="pt-3 pt-lg-5 pb-lg-3 d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block text-justify">Prendre contact avec l'équipe</h3>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div className="col-12 d-flex inter espace pt-5">
             <div className="row g-0 align-content-center w-100 mt-5 mb-5">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-10 col-xxl-7 mx-auto">
                 <span className="d-block d-sm-block d-md-block d-lg-block d-xl-none d-xxl-none fond_texte">
                   <h5 className="text-center">Prendre contact <span className="d-block couleur">avec l'équipe</span></h5>
                 </span>
                 <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                   <div className="row d-flex entete__bordure fond_texte">
                     <div className="col-4 align-self-center">
                       <h6 className="couleur"><em>Commencer maintenant</em></h6>
                     </div>
                     <div className="col-8 align-self-center">
                       <h5 className="text-right d-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block">Un projet <span className="d-block couleur"><strong>à concrétiser ?</strong></span></h5>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div className="container-fluid pt-5 pb-5 fond-blanc">
             <div className="row mx-auto justify-content-center d-flex">
                 <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mx-atuo mb-5 mb-sm-0 align-self-center">
                   <p className="text-justify">Un projet à mener à bien, une simple question à poser ? Nous vous répondrons. Nous intervenons sur toute la France et à l'export, pour tous vos projets de fooding.</p>
                 </div>
                 <div className="col-xs-3 col-sm-12 col-md-8 col-lg-5 col-xl-5 col-xxl-10 mb-5 align-self-center mb-sm-0 pt-xl-5">
                   <div className="col-10 mx-auto ombre p-3 fond-gris bordure_haut-row bordure_gauche-row bordure_droite-row bordure_haut-row">
                         <div id="envoye"><p className="text-center">Votre message a bien été envoyé. Nous vous répondrons dans les plus brefs délais.</p><p className="text-center">Merci d'avoir contacté MEDIA Restauration France.</p></div>
    <form onSubmit={formik.handleSubmit, sendEmail} id="message">
      <h6 className="text-center pt-1 pb-2">A vous de jouer :</h6>
   <div className="row">
     <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex flex-column">
        <select
        type="select" 
        value={formik.values.titre}
           onChange={formik.handleChange}
            name="titre"
            >
              <option value="">--Veuillez choisir--</option>
        <option value="Mme">Mme</option>
        <option value="M">M</option>
              </select>
              {formik.errors.titre ? (
            <span className="errorText">{formik.errors.titre}</span>
          ) : null}
          <input
            type="text"
            name="name"
            placeholder="Votre nom*"
            className={formik.errors.name ? "error" : null}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name ? (
            <span className="errorText">{formik.errors.name}</span>
          ) : null}
           <input
            type="text"
            name="societe"
            placeholder="Votre société*"
            className={formik.errors.societe ? "error" : null}
            onChange={formik.handleChange}
            value={formik.values.societe}
          />
          {formik.errors.societe ? (
            <span className="errorText">{formik.errors.societe}</span>
          ) : null}
          <input
            type="email"
            name="email"
            placeholder="Votre adresse email*"
            className={formik.errors.email ? "error" : null}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email ? (
            <span className="errorText">{formik.errors.email}</span>
          ) : null}
     </div>
       <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 d-flex flex-column align-self-center h-100">
          <textarea
            name="message"
            placeholder="Votre message*"
            className={formik.errors.message ? "error" : null}
            onChange={formik.handleChange}
            value={formik.values.message}
            required
          />
          {formik.errors.message ? (
            <span className="errorText">{formik.errors.message}</span>
          ) : null}
          <button className="bouton" type="submit" id="envoi" value="Send">Envoyer</button>
       </div>
   </div>
    </form>                
                     </div>
                   </div>
               </div>
             </div>
            
           </div>
           </div>
           </>
  );
}