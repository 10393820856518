import React from "react";

export default function RefUrl(props) {
  return (
    <div className="col-xs-6 col-sm-6 col-md-8 col-lg-5 col-xl-6 col-xxl-3 mx-auto mt-n5 mt-lg-3 p-5">
      <p>
        <a href={props.url} target="_blank" rel="noreferrer" className="bouton">
          Consulter le site &nbsp;<span className="icone_lien_externe"></span>
          &nbsp;&nbsp;&gt;
        </a>
      </p>
    </div>
  );
}
