import React from "react";

export default function MaitreRestaurateur(props) {
  return (
    <img
      data-src="https://static.mediarestauration.fr/img/maitre-restaurateur.svg"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      alt="maitre restaurateur"
      width="45%"
      className="lazyload"
    />
  );
}
