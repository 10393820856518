import "./App.css";
import "./Bootstrap.css";
import "./Structure.css";
import React, { Component, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Bas from "./composants/basDuSite.js";

import Accueil from "./composants/Accueil.js";
import NosServices from "./composants/NosServices.js";
import Historique from "./composants/Historique.js";
import NosProjets from "./composants/NosProjets.js";
import NosReferences from "./composants/NosReferences.js";
import ContactezNous from "./composants/ContactezNous.js";
import Error from "./composants/Erreur.js";
import Navigation from "./composants/Navigation.js";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Wrapper>
          <Navigation />
          <Routes location={window.location} key={window.location.pathname}>
            <Route exact path="/" element={<Accueil />} exact />
            <Route path="/nos-services" element={<NosServices />} />
            <Route path="/historique" element={<Historique />} />
            <Route path="/nos-references" element={<NosReferences />} />
            <Route path="/nos-projets" element={<NosProjets />} />
            <Route path="/contactez-nous" element={<ContactezNous />} />

            <Route component={Error} />
          </Routes>
          <Bas />
        </Wrapper>
      </BrowserRouter>
    );
  }
}

export default App;
